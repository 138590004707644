export const Organization = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  url: 'https://loadsmart.com',
  name: 'Loadsmart',
  telephone: '+1 646 887 6278',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Chicago',
    addressRegion: 'IL',
    postalCode: '60604',
    streetAddress: '175 W. Jackson Blvd Suite 1400',
  },
};

export const JsonLd = ({ data }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  );
};
