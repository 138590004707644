import { NextSeo } from 'next-seo';
import Head from 'next/head';

//screens
import { Home } from '/screens/';

//data-helpers
import { JsonLd, Organization } from '@/datafiles/micro-data';

export default function Main() {
  return (
    <>
      <Head>
        <meta
          name="keywords"
          content="freight brokerage, freight technology, freight software, LTL, FTL, managed transporation, shipper tech, carrier software, "
        />
      </Head>
      <NextSeo
        nofollow={process.env.NOINDEX_FLAG}
        noindex={process.env.NOINDEX_FLAG}
        canonical="https://loadsmart.com/"
        title="ShipperGuide TMS | Managed Transportation | Dock & Yard Solutions | Freight Brokerage | Carrier TMS"
        openGraph={{
          url: 'https://loadsmart.com/',
        }}
      />
      <JsonLd data={Organization} />
      <Home />
    </>
  );
}
